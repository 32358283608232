import './Filters.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FILTERS_ENUM } from './FiltersData';
import React from 'react';
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';

function Filters({days, filters, showAllClickHandler}) {

    const [isExpanded, setIsExpanded] = useState(false);

    const handleFiltersClick = () => {
        setIsExpanded(!isExpanded);
    }

    const getClassName = (filter) => {
        if (filter.text === FILTERS_ENUM.AM) {
            return 'flip';
        } else {
            return;
        }
    }

    const daysHTML = days.map((day) => {
        return (
            <div className={"dayFilter " + (day.isSelected ? 'isSelected': '')} key={day.text} onClick={() => day.onClick(day.text)}>
                {day.text}
            </div>
        )
    })

    const filtersHTML = filters.map((filter) => {
        return (
            <button className={"filter " + (filter.isSelected ? 'isSelected': '')} key={filter.text} onClick={() => filter.onClick(filter.text)}>
                <FontAwesomeIcon className={getClassName(filter)} icon={filter.icon} />
                <h3 className="filterName">{filter.text}</h3>
            </button>
        )
    })

    const combinedFilters = [...days, ...filters];
    const numSelectedFilters = combinedFilters.filter(f => f.isSelected).length;
    const clearAllHTML = (
        <div className="showAll">
            <button onClick={showAllClickHandler}>Clear all ({numSelectedFilters})</button>
        </div>
    )

    return (
        <div className="filtersContainer">
            <div className="filtersHeader">
                <h2 onClick={handleFiltersClick} className="filtersButton"><FontAwesomeIcon icon={faFilter} /> Filters</h2>
                {numSelectedFilters > 0 && clearAllHTML}
            </div>
            <div className={`filtersCollapsible ${isExpanded ? 'isExpanded' : ''}`}>
                <div className="daysFilter">
                    {daysHTML}
                </div>
                <div className="filters">
                    {filtersHTML}
                </div>
            </div>
        </div>
    )
}

export default Filters;