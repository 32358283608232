import Filters from './Filters.js'
import './Activities.css';
import { useState } from 'react';
import activities from './ActivitiesData.js';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faTree } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { faTicket } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FILTERS_ENUM } from './FiltersData.js';
import React from 'react';

function Activities() {
    const getToday = () => {
        const date = new Date();
        const day = date.getDay();
        return day;
    }

    const today = getToday();

    const handleDayClick = (val)=> {
        const updatedDaysData = daysData.map((day) => {
            if (day.text === val) {
                day.isSelected = true;
             } else {
                day.isSelected = false;
             }
            return day
        });

        setDays(updatedDaysData);
    }

    const toggleFilter = (val) => {
        const updatedFiltersData = filters.map((filter) => {
            if (filter.text === val) {
                filter.isSelected = !filter.isSelected;
            }
            return filter
        });
        
        setFilters(updatedFiltersData);
    }

    const handleShowAllClick = () => {
        // if show all is clicked, clear all other filters
        const updatedFiltersData = filters.map((filter) => {
            filter.isSelected = false;
            return filter
        });

        const updatedDaysData = daysData.map((day) => {
            day.isSelected = false;
            return day
        });

        setDays(updatedDaysData);
        setFilters(updatedFiltersData);
    }

    const filtersData = [
        {
            text: FILTERS_ENUM.AM,
            icon: faClock,
            isSelected: false,
            onClick: toggleFilter
        },
        {
            text: FILTERS_ENUM.PM,
            icon: faClock,
            isSelected: false,
            onClick: toggleFilter
        },
        {
            text: FILTERS_ENUM.INDOOR,
            icon: faHouse,
            isSelected: false,
            onClick: toggleFilter
        },
        {
            text: FILTERS_ENUM.OUTDOOR,
            icon: faTree,
            isSelected: false,
            onClick: toggleFilter
        },
        {
            text: FILTERS_ENUM.FREE,
            icon: faMoneyBill,
            isSelected: false,
            onClick: toggleFilter
        },
        {
            text: FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED,
            icon: faTicket,
            isSelected: false,
            onClick: toggleFilter
        },
        {
            text: FILTERS_ENUM.FITNESS,
            icon: faHeart,
            isSelected: false,
            onClick: toggleFilter
        },
    ]

    const daysData = [
        {
            day: 1,
            text: 'M',
            isSelected: false,
            onClick: handleDayClick
        },
        {
            day: 2,
            text: 'TU',
            isSelected: false,
            onClick: handleDayClick
        },
        {
            day: 3,
            text: 'W',
            isSelected: false,
            onClick: handleDayClick
        },
        {
            day: 4,
            text: 'TH',
            isSelected: false,
            onClick: handleDayClick
        },
        {
            day: 5,
            text: 'F',
            isSelected: false,
            onClick: handleDayClick
        },
        {
            day: 6,
            text: 'SA',
            isSelected: false,
            onClick: handleDayClick
        },
        {
            day: 0,
            text: 'SU',
            isSelected: false,
            onClick: handleDayClick
        },
    ]

    const [filters, setFilters] = useState(filtersData);
    const [days, setDays] = useState(daysData);

    const shouldShowActivity = (activity) => {
        // get the selected days
        const selectedDays = days.filter((day) => day.isSelected);

        // get the selected filters
        const selectedFilters = filters.filter((filter) => filter.isSelected);
        
        // if no filters are selected, show the activity 
        if (selectedDays.length <=0 && selectedFilters.length <= 0) {
            return true;
        } 

        if (selectedDays.length > 0) {
            // if the activity is not on all of the selected days, don't show the activity
            // check that the activity is on all the selected days
            const activityIsOnEverySelectedDay = selectedDays.every(selectedDay => activity.days.includes(selectedDay.day));
            if (!activityIsOnEverySelectedDay) {
                return null;
            }
        }

        // check that all selected filters are included 
        // in the applicable filters for this activity
        // example:
        // selectedFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR]
        // applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR]
        // should not show activity
        for (let i = 0; i < selectedFilters.length; i++) {
            const selectedFilter = selectedFilters[i].text;
            if (!activity.applicableFilters.includes(selectedFilter)) {
                return null;
            }
        }

        return true;
    }
    
    const activitiesHTML = activities.map((activity) => {
        if (shouldShowActivity(activity)) {
            return (
                <li className={"activity"} key={activity.name}>
                    <div className="cardTop">
                        <h3>{activity.name}</h3>
                        <p className="cardSubtitle">{activity.suburb}</p>
                    </div>
                    <div className="cardMiddle">{activity.description}</div>
                    <div className="cardBottom">
                        <div className="cardAction">
                            <a href={activity.websiteUrl} target="_blank" rel="noopener noreferrer"> GO TO SITE</a>
                        </div>
                        <div className="cardAction">
                            <a href={activity.locationUrl} target="_blank" rel="noopener noreferrer">GO TO MAP</a>
                        </div>
                    </div>
                </li>
            )
        } else {
            return null;
        }
    })

    const activitiesToShow = activitiesHTML.filter(a => a);

    if (activitiesToShow.length > 0) {
        return (
            <div className="filtersActivitesContainer">
                <Filters days={days} filters={filters} showAllClickHandler={handleShowAllClick}/>
                <ul className="activities" id="activities">
                    {activitiesHTML}
                </ul>
            </div>
        )
    } else {
        return (
            <div className="filtersActivitesContainer">
                <Filters days={days} filters={filters} showAllClickHandler={handleShowAllClick}/>
                <div className="filtersActivitesContainer">
                    <h3>Oops, there are no activities for those filters. Clear your filters and try again.</h3>
                </div>
            </div>

        )
    }


}

export default Activities;