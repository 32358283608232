import {FILTERS_ENUM} from './FiltersData.js';

const activities = [
    {
        name: 'Symbio Wildlife Park',
        description: 'Feed the kangaroos and goats, then hit the playground and waterpark.',
        days: [0,1,2,3,4,5,6],
        cost: 42,
        locationUrl: 'https://maps.app.goo.gl/1wJiogvQEaXrgzm77',
        websiteUrl: 'https://checkout.roller.app/symbiozoo/products/dayswithbub',
        openTime: '0930',
        closeTime: '1700',
        suburb: 'Helensburgh',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
    {
        name: 'Air Born Cheerleading',
        description: 'Free play with trampolines, swings and more at a cheerleading gym.',
        days: [1, 3],
        cost: 4,
        locationUrl: 'https://maps.app.goo.gl/ecikvJFfk9sJPhh77',
        websiteUrl: 'https://www.airborncheerleading.com/',
        openTime: '0930',
        closeTime: '1100',
        suburb: 'Woonona',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
    {
        name: 'Austi Church Playtime',
        description: 'Music session followed by morning tea and free play.',
        days: [2],
        cost: 4,
        locationUrl: 'https://maps.app.goo.gl/YZdkWnsMt7Sfs5JS8',
        websiteUrl: 'https://austianglicanchurch.org.au/connect/kids/',
        openTime: '0930',
        closeTime: '1100',
        suburb: 'Austinmer',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
   {
    name: 'Baby Sensory',
    description: 'Classes full of sounds, smells and sights for bubs up to 13 months.',
    days: [2,3],
    cost: 25,
    locationUrl: 'https://maps.app.goo.gl/WsJZ8h5ZqsdZu8Yt7',
    websiteUrl: 'https://www.babysensory.com.au/wollongong-nsw/',
    suburb: 'Bulli / Wollongong',
    applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR]
},
    {
        name: 'Botanical Gardens',
        description: 'Feed the ducks some peas, play in the sandpit and explore the rose garden.',
        days: [0, 1, 2, 3, 4, 5, 6],
        cost: 0,
        locationUrl: 'https://maps.app.goo.gl/EmaEn7t2Y3T7fu2V6',
        websiteUrl: 'https://wollongongbotanicgarden.com.au/',
        openTime: '0700',
        closeTime: '1700',
        suburb: 'Wollongong',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FREE]
    },
    {
        name: 'Bunnings playground',
        description: 'Great for a rainy day.',
        days: [0,1,2,3,4,5,6],
        cost: 0,
        locationUrl: 'https://maps.app.goo.gl/Vyj29h7Q1yBsBAGj9',
        websiteUrl: 'https://www.bunnings.com.au/stores/nsw/bellambi?srsltid=AfmBOoptOC33XjZVE4CJtxC0T0zWOqAf3JXvtn1Q8F3vsA8idExBecP9',
        openTime: '0700',
        closeTime: '1900',
        suburb: 'Bellambi',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FREE]
    },
    {
        name: 'CarLovers Car Wash',
        description: 'Clean your car while entertaining bub at the same time, open 24/7.',
        days: [0,1,2,3,4,5,6],
        cost: 20,
        locationUrl: 'https://maps.app.goo.gl/3osc3R1hGJ3rSPxz8',
        websiteUrl: 'https://www.facebook.com/carloverscarwashwollongong',
        suburb: 'Wollongong',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
       },
       {
        name: 'Chouchou Bebe Adventure',
        description: 'A pastel ball pit, fishing game, plenty of lego, and free coffee on entry.',
        days: [0,1,2,3,4,5,6],
        cost: 12,
        locationUrl: 'https://maps.app.goo.gl/wmMvW79MHCWt1wG38',
        websiteUrl: 'https://www.chouchoubebeadventure.com/properties/wollongong',
        openTime: '0900',
        closeTime: '1700',
        suburb: 'Wollongong',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
       {
        name: 'Circus Monoxide',
        description: 'Check out the microbats circus class for little ones.',
        days: [2,4,6],
        cost: 25,
        locationUrl: 'https://maps.app.goo.gl/eHaLHoj7AXeRsrDt7',
        websiteUrl: 'https://www.circusmonoxide.com.au/circus-school',
        suburb: 'Fairy Meadow',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR]
    },
    {
        name: 'Corrimal Heated Pool',
        description: 'Swim in the heated pool, follow with a play / read at the library next door.',
        days: [0,1,2,3,4,5,6],
        cost: 2,
        locationUrl: 'https://maps.app.goo.gl/xLZxwnQch1ScgUba9',
        websiteUrl: 'https://wollongong.nsw.gov.au/places/pools/pools/freshwater/corrimal-heated-pool',
        openTime: '0600',
        closeTime: '1800',
        suburb: 'Corrimal',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },

    {
        name: 'Early Start Discovery Space',
        description: 'Bub can learn, explore and create for hours in this children\'s museum.',
        days: [0,1,2,3,4,5,6],
        cost: 25,
        locationUrl: 'https://maps.app.goo.gl/xMYt67YtKGGFF2Fg9',
        websiteUrl: 'https://www.earlystartdiscoveryspace.edu.au/',
        suburb: 'Wollongong',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.PM, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
    {
        name: 'Flip Out',
        description: 'Trampolines, soft play, basketballs and a party vibe.',
        days: [0,1,2,3,4,5,6],
        cost: 20,
        locationUrl: 'https://maps.app.goo.gl/vaEFokmY2XQVJHjf6',
        websiteUrl: 'https://flipout.com.au/',
        suburb: 'North Wollongong',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
    {
        name: 'Fo Guang Shan Nan Tien',
        description: 'Explore the Buddhist temple and grab a bite to eat.',
        days: [0,2,3,4,5,6],
        cost: 0,
        locationUrl: 'https://maps.app.goo.gl/WYs2YEkAgpFV3A349',
        websiteUrl: 'https://www.nantien.org.au/',
        openTime: '0900',
        closeTime: '1700',
        suburb: 'Berkely',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FREE]
    },
    {
        name: 'Fruit picking',
        description: 'Tractor ride, fruit picking and local cider at Glenbernie Orchard.',
        days: [6,0],
        cost: 5,
        locationUrl: 'https://maps.app.goo.gl/UFiPuahgNFSc6WqM9',
        websiteUrl: 'https://www.darkes.com.au/whats-on',
        openTime: '1030',
        closeTime: '1700',
        suburb: 'Darkes Forest',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
    {
        name: 'Good Work Studio',
        description: 'Book a bub friendly exercise class at Good Work Studio.',
        days: [1,2,3,4,5],
        cost: 40,
        locationUrl: 'https://maps.app.goo.gl/35jzWFwofy9SocYu5',
        websiteUrl: 'https://www.good-work.com.au/',
        openTime: '0900',
        closeTime: '1700',
        suburb: 'Thirroul',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.PM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.FITNESS]
    },
    {
        name: 'Illawarra Live Steamers',
        description: 'Miniature steam train rides on the fourth Sunday of the month.',
        days: [0],
        cost: 20,
        locationUrl: 'https://maps.app.goo.gl/xxU3u8VAtcPnFMHZA',
        websiteUrl: 'https://www.ils.org.au/',
        suburb: 'Wollongong',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
    {
        name: 'Illawarra Rhododendron & Rainforest Gardens',
        description: 'These secret gardens are a hidden gem.',
        days: [2,6,0],
        cost: 5,
        locationUrl: 'https://maps.app.goo.gl/FGtUzJE4ycEa3Cgv6',
        websiteUrl: 'https://irrg.au/',
        openTime: '1030',
        closeTime: '1700',
        suburb: 'Mount Keira',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
    },
    {
        name: 'Kindergym',
        description: 'Bubs can roll, climb, jump and swing at these gymnastics classes.',
        days: [1,3,5],
        cost: 160,
        locationUrl: 'https://maps.app.goo.gl/FGtUzJE4ycEa3Cgv6',
        websiteUrl: 'https://irrg.au/',
        openTime: '1030',
        closeTime: '1700',
        suburb: 'Bulli',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR]
    },
    {
       name: 'Library',
       description: 'Local libraries have play / read corners for bubs, and activies like Toddlertime and Wriggle & Jiggle.',
       days: [1,2,3,4,5,6],
       cost: 0,
       locationUrl: 'https://www.wollongong.nsw.gov.au/library/your-library/locations-and-hours',
       websiteUrl: 'https://www.wollongong.nsw.gov.au/library/your-library/locations-and-hours',
       openTime: '0930',
       closeTime: '1700',
       suburb: 'Thirroul / Corrimal / Wollongong',
       applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FREE]
   },
    {
        name: 'Little Kickers',
        description: 'Plenty of kicking, running and scoring at group soccer practice.',
        days: [4,6,0],
        cost: 20,
        locationUrl: 'https://maps.app.goo.gl/Lk6G7qHsZku4iES47',
        websiteUrl: 'https://www.littlekickers.com.au/',
        openTime: '0930',
        closeTime: '1015',
        suburb: 'Thirroul / Woonona / Wollongong',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.FITNESS]
    },
    {
        name: 'Madden Falls bush walk',
        description: 'Spy lizards and birds on this kid friendly walk to a waterfall.',
        days: [0,1,2,3,4,5,6],
        cost: 0,
        locationUrl: 'https://maps.app.goo.gl/neAwawiqgZUqELAA8',
        websiteUrl: 'https://www.nationalparks.nsw.gov.au/things-to-do/lookouts/maddens-falls',
        openTime: '0900',
        closeTime: '1700',
        suburb: 'Helensburgh',
        applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FITNESS, FILTERS_ENUM.FREE]
    },
    {
        name: 'Miss Zoe School of Dance',
        description: 'Dance fundamentals for little monkeys.',
        days: [1,2,3,4,5,6],
        cost: 25,
        locationUrl: 'https://maps.app.goo.gl/gLYQJW3NnDW6CTq5A',
        websiteUrl: 'https://www.misszoesschoolofdance.com.au/',
        openTime: '0930',
        closeTime: '1200',
        suburb: 'Coledale / Wollongong',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.PM, FILTERS_ENUM.INDOOR]
    },
    {
        name: 'MusiKidabra',
        description: 'Music and movement for bubs and young kids.',
        days: [1, 2, 4],
        cost: 25,
        locationUrl: 'https://maps.app.goo.gl/QGeyShbE6b1rAa1X7',
        websiteUrl: 'https://www.kindermusik.com/studio/53262',
        openTime: '0930',
        closeTime: '1200',
        suburb: 'Fairy Meadow',
        applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR]
    },
   {
       name: 'Music Time',
       description: 'Singing, playing and dancing with musical instruments.',
       days: [1, 4],
       cost: 25,
       locationUrl: 'https://maps.app.goo.gl/2N1phAFLqm7C86UP8',
       websiteUrl: 'https://www.kaijasings.com/product/music-time-at-thirroul/',
       openTime: '0930',
       closeTime: '1200',
       suburb: 'Thirroul',
       applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR]
   },
   {
    name: 'Peaceful Babies Playgroup',
    description: 'Free play for young bubs in a small, supportive group setting.',
    days: [2],
    cost: 80,
    locationUrl: 'https://maps.app.goo.gl/4VXhLzoV2bwJCSLDA',
    websiteUrl: 'https://www.nelliehodda.com/peaceful-babies-and-parents-playgroup',
    openTime: '0930',
    closeTime: '1200',
    suburb: 'Corrimal',
    applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR]
},
   {
    name: 'Ready Steady Go Kids',
    description: 'A different sport each week for bub to learn and have fun with.',
    days: [1,2,3],
    cost: 10,
    locationUrl: 'https://maps.app.goo.gl/Qu6Fb2dg14quUZNr7',
    websiteUrl: 'https://www.readysteadygokids.com.au/',
    openTime: '1200',
    closeTime: '1900',
    suburb: 'Bulli',
    applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.FITNESS]
},
   {
    name: 'Rosies Fish & Chips',
    description: 'Tasty fish and chips by Sharkeys Beach.',
    days: [3,4,5,6,0],
    cost: 10,
    locationUrl: 'https://maps.app.goo.gl/YBeEWeDe3HWq4hgD8',
    websiteUrl: 'https://rosiesfishandchips.com.au/',
    openTime: '1200',
    closeTime: '1900',
    suburb: 'Coledale',
    applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
},
   {
    name: 'Sea Cliff Bridge walk',
    description: 'Enjoy stunning views along the Sea Cliff Bridge.',
    days: [0,1,2,3,4,5,6],
    cost: 0,
    locationUrl: 'https://maps.app.goo.gl/ZFZubz649ToTxkzN9',
    websiteUrl: 'https://www.visitnsw.com/destinations/south-coast/wollongong-and-surrounds/stanwell-park/attractions/sea-cliff-bridge',
    openTime: '0900',
    closeTime: '1700',
    suburb: 'Coalcliff',
    applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FITNESS, FILTERS_ENUM.FREE]
},
{
    name: 'Sharkys Play',
    description: 'Soft play and slippery slides at the bowling alley. Bring socks.',
    days: [0,1,2,3,4,5,6],
    cost: 25,
    locationUrl: 'https://maps.app.goo.gl/CYL2AF8k5u6P5Wq6A',
    websiteUrl: 'https://www.sharkysplay.com.au/',
    suburb: 'Bellambi',
    applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.PM, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
},
{
    name: 'Tennis lesson',
    description: 'Book a toddler tennis lesson with Luke at Thirroul Tennis Club.',
    days: [1,2,3,4,5],
    cost: 40,
    locationUrl: 'https://maps.app.goo.gl/krw89pvMfBzaTnBHA',
    websiteUrl: 'http://www.thirroultennisclub.com/',
    openTime: '0900',
    closeTime: '1700',
    suburb: 'Thirroul',
    applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.PM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.FITNESS]
},
{
    name: 'Thirroul Pool',
    description: 'Shady, enclosed, ocean water toddler pool. Plus a full size pool with lanes. Perfect for summer days.',
    days: [0,1,2,3,4,5,6],
    cost: 0,
    locationUrl: 'https://maps.app.goo.gl/FpbbsseCVGGmpkLS7',
    websiteUrl: 'https://wollongong.nsw.gov.au/places/pools/pools/saltwater/thirroul-pool',
    openTime: '0930',
    closeTime: '1700',
    suburb: 'Thirroul',
    applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FREE]
},
{
    name: 'TimberNook',
    description: 'Dust, dirt, mud and fun. Bring gumboots and spare clothes to this natural bush experience.',
    days: [1,3,4,5],
    cost: 25,
    locationUrl: 'https://maps.app.goo.gl/63D36V5x7QqD8sE78',
    websiteUrl: 'https://timbernook.com/provider/timbernook-southern-sydney/',
    suburb: 'Appin',
    applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR]
},
{
    name: 'Treetops',
    description: 'Toys, crafts and soft play. Treetops also offers a work-play program on Tuesdays.',
    days: [1, 3, 4, 5],
    cost: 5,
    openTime: '0930',
    closeTime: '1230',
    applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED],
    suburb: 'Thirroul',
    locationUrl: 'https://maps.app.goo.gl/Mg5z1tyRp9shZDbV7',
    websiteUrl: 'https://www.elevateworkspace.com.au/bookplaytime',
},
{
    name: 'UOW Science Space',
    description: 'Dinosaurs! What more could bub want?',
    days: [0, 1, 2, 3, 4, 5, 6],
    cost: 5,
    openTime: '0930',
    closeTime: '1230',
    applicableFilters: [FILTERS_ENUM.AM, FILTERS_ENUM.PM, FILTERS_ENUM.INDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED],
    suburb: 'North Wollongong',
    locationUrl: 'https://maps.app.goo.gl/NsqwTgvuV7hiWApT7',
    websiteUrl: 'https://sciencespace.com.au/',
},
{
    name: 'Wombarra Bowlo',
    description: 'Lawn bowls, ocean views and the best Thai food in the area.',
    days: [0,1,2,3,4,5,6],
    cost: 20,
    locationUrl: 'https://maps.app.goo.gl/1Qddk4N3zXxfFLW2A',
    websiteUrl: 'https://www.bowlo.com.au/',
    openTime: '1130',
    closeTime: '2200',
    suburb: 'Wombarra',
    applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED]
},
  


       // {
   //     name: 'Beach',
   //     description: 'Click through to discover beaches in the area.',
   //     days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
   //     cost: 0,
   //     locationUrl: null,
   //     websiteUrl: '/beaches',
   //     openTime: '0600',
   //     closeTime: '1800',
   //     suburb: null,
   //     applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FREE]
   // },
   // {
   //     name: 'Ocean pool',
   //     description: 'Click through to discover ocean pools in the area.',
   //     days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
   //     cost: 0,
   //     locationUrl: null,
   //     websiteUrl: '/oceanpools',
   //     openTime: '0600',
   //     closeTime: '1800',
   //     suburb: null,
   //     applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FREE]
   // },

   // {
   //     name: 'Playground',
   //     description: 'Click through to discover playgrounds in the area.',
   //     days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
   //     cost: 0,
   //     locationUrl: null,
   //     websiteUrl: '/playgrounds',
   //     openTime: '0600',
   //     closeTime: '1800',
   //     suburb: null,
   //     applicableFilters: [FILTERS_ENUM.PM, FILTERS_ENUM.AM, FILTERS_ENUM.OUTDOOR, FILTERS_ENUM.NO_BOOKING_OR_SIGNUP_REQUIRED, FILTERS_ENUM.FREE]
   // },
]


export default activities;

