import './Home.css';
import Activities from './Activities.js';
import React from 'react';
import logoSvg from './DWB_03.svg';

function Home() {
  const isMobile = window.innerWidth <= 767;
  const subheadingContainer = (
    <div className="subheadingContainer">
      <h2 className={"subheading"}>Activities for you and bub, from Helensburgh to Wollongong.</h2>
    </div>
  )
  return (
    <div className={"content " + "home"}>
        <div className="landing">
          <div className="imageContainer"></div>
          <img alt="Days With Bub logo" src={logoSvg}/>
          {isMobile && subheadingContainer}
        </div>
        {!isMobile && subheadingContainer}
        <Activities/>
    </div>
  );
}

export default Home;
