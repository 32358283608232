import './global.css';
import './Root.css';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { Outlet, Link } from "react-router-dom";
import Home from './Home';
import Footer from './Footer';
import logoStackedSvg from './DWB_01_stacked.svg';

function Root () {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const handleNavClick = () => {
        setIsOpen(!isOpen);
    }

    const handleNavItemClick = () => {
        setIsOpen(false);
    }

    return (
        <div className="container montserrat-400">
            <header>
                <a href="/"><img alt="Days With Bub logo" src={logoStackedSvg}/></a><FontAwesomeIcon onClick={handleNavClick} icon={faBars} />
            </header>
            <div className={`navigation ${isOpen ? 'isOpen' : ''}`}>
                <div className="navHeader"><FontAwesomeIcon onClick={handleNavClick} icon={faX} /></div>
                <ul>
                    <li className={location.pathname === '/' ? 'isActive' : ''} onClick={handleNavItemClick}><Link to="/">Home</Link></li>
                    <li className={location.pathname === '/about' ? 'isActive' : ''} onClick={handleNavItemClick}><Link to="/about">About</Link></li>                    
                </ul>
            </div>
            <div id="detail">
                {(location.pathname === '/') ? <Home /> : null}
                <Outlet/>
            </div>
            {<Footer/>}
        </div>
    )
}

export default Root;