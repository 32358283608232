import './About.css';

function About () {
    return (
        <div className="about content">
            <div className="aboutContentContainer">
                <div className="aboutImageContainer"></div>
                <div className="aboutStory">
                    <h2 className="aboutHeader">My story</h2>
                    <p>Hi, I’m Amy! I live in Thirroul and spend most of my days looking after my toddler. </p>
                    <p>Before becoming a mum I was a software engineer with Twitter in London and San Francisco. And before that I was a journalist with the ABC in Brisbane and Sydney. I live a very different life these days - hopping between playgrounds, libraries, ball pits and the rest of it. Always on the lookout for new activities to do with bub.</p>
                    <p>I was inspired to build Days With Bub because, let's be honest, days with bub can be both wonderful and hard. I built Days With Bub to reduce some of my own mental load, and make getting out of the house just that little bit easier. I hope it does the same for you.</p>
                </div>
            </div>
            <div className="aboutContentContainer">
                <div className="aboutStory">
                    <h2 className="aboutHeader joinHeader">Join the community</h2>
                    <p>Join the Days With Bub community via our <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/share/g/22Ngh61TXYZqLXd4/">Facebook group</a>. Feel free to share your plans with bub and invite others along.</p>
                </div>
            </div>
            <div className="aboutContentContainer">
                <div className="aboutStory">
                    <h2 className="aboutHeader feedbackHeader">Your feedback</h2>
                    <p>I'd love your feedback! Whether it's an idea for the website, or you'd just like to to say hi, shoot me an email at <a href="mailto:dayswithbub@gmail.com">dayswithbub@gmail.com</a></p>
                </div>
            </div>
        </div>
    )
}

export default About;