import './Footer.css';
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons'
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'


function Footer () {
    return (
        <div className="footer">
            <a className="email" href="mailto:dayswithbub@gmail.com"><FontAwesomeIcon icon={faEnvelope} />Get in touch</a>
            <div className="social">
                <a href="https://www.facebook.com/share/g/22Ngh61TXYZqLXd4/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="fbIcon" icon={faSquareFacebook} /></a>
                <a href="https://www.instagram.com/days_with_bub/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="fbIcon" icon={faSquareInstagram} /></a>
            </div>
        </div>
    )
}

export default Footer;